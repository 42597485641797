import { Box, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import api, { Action } from "../../../api";
import { RecordActionsProperties } from "../../../model/ViewProperties";
import { WorkflowStates } from "../../../model/WorkflowStates";
import { getNextState } from "../../../utils/utils";
import { ActionButton } from "../buttons/ActionButton";
import { buttonColor } from "../buttons/buttonProperties";
import { alertConfirm } from "../notification/notificationDialogs";
import { successSnackbarOptions } from "../notification/notifications";
import SelectExclusionStates from "../selectors/SelectStates";

export function RecordActions({
  state,
  currentRecord,
  updateRecord,
  handleExclusionSelection,
  disableRecordActions,
  disableBackwards = true,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    showExclusionSelect,
    showBackward,
    showForward,
    showToExclude,
    showPaperDiscussion,
  } = RecordActionsProperties[state];

  const disabled =
    disableRecordActions || state !== currentRecord.workflowState;

  const disableExcludeButton =
    disabled || currentRecord.exclusionState === "NOT_EXCLUDED";

  const disabledForward = currentRecord.exclusionState !== 'NOT_EXCLUDED' && (state === 'LONG_TERM_TO_BE_DISCUSSED' || state === 'TO_BE_DISCUSSED' || state === 'SELECTION_ASSESSMENT_REVIEW') ? true : false; //disabled || (showToExclude && !disableExcludeButton);
  const disabledDiscuss =
    disabled ||
    (!disableExcludeButton &&
      currentRecord.workflowState === WorkflowStates.TO_BE_DISCUSSED.state);

  const onMoveForward = () => {
    onMove(Action.FORWARD);
  };

  const onMoveBackward = () => {
    onMove(Action.BACKWARD);
  };

  const onMoveToState = () => {
    onMove(Action.TO_STATE);
  };

  const onMove = (action) => {
    const { confirm, msg } = needsConfirmation(currentRecord, action);
    if (confirm) {
      alertConfirm("Move record " + action, msg, onMoveConfirmed, action);
    } else {
      onMoveConfirmed(action);
    }
  };

  const onMoveConfirmed = (action) => {
    if (action === Action.TO_STATE) {
      let toState;
      if (
        state === WorkflowStates.RELEASE_VALIDATION.state ||
        state === WorkflowStates.RELEASE_VALIDATION_REVIEW.state
      ) {
        toState = WorkflowStates.ANALYSIS.state;
      } else if (state !== WorkflowStates.TO_BE_DISCUSSED.state) {
        toState = WorkflowStates.TO_BE_DISCUSSED.state;
      } else {
        toState = currentRecord.previousWorkflowState;
      }

      console.log(toState);
      api
        .moveToState(currentRecord.id, state, toState)
        .then((response) => {
          updateRecord(response.data);
          enqueueSnackbar("Record successfully moved", successSnackbarOptions);
        })
        .catch((e) => console.log("move to state error: " + e));
    } else {
      api
        .moveRecord(currentRecord, action)
        .then((data) => {
          updateRecord(data);
          enqueueSnackbar("Record successfully moved", successSnackbarOptions);
        })
        .catch((e) => console.log("move forward error: " + e));
    }
  };

  if (showToExclude) {
    var excludeButton = (<ActionButton
      text="Exclude"
      captionText={null}
      disabled={disableExcludeButton}
      handleClick={onMoveForward}
      color={buttonColor.exclusion}
    />)
  }

  if (showBackward) {
    var backwardButton = (<ActionButton
      text="Backward"
      captionText={getNextState(state, Action.BACKWARD)}
      disabled={disableBackwards && disabled}
      handleClick={onMoveBackward}
      color={buttonColor.backward}
    />)
  }

  if (showForward) {
    var forwardButton = (<ActionButton
      text="Forward"
      captionText={null}
      disabled={disabledForward}
      handleClick={onMoveForward}
      color={buttonColor.forward}
      variant="contained"
      fullWidth={true}
    />)
  }

  if (showPaperDiscussion) {
    let text;
    let caption;
    let disColor;

    if (
      state === WorkflowStates.RELEASE_VALIDATION.state ||
      state === WorkflowStates.RELEASE_VALIDATION_REVIEW.state
    ) {
      // The user can put the paper back to annotation if needed
      text = "Annotation";
      caption = "To Analysis Annotation";
      disColor = buttonColor.discuss;
    } else if (state !== WorkflowStates.TO_BE_DISCUSSED.state) {
      text = "Discuss";
      caption = "To paper discussion";
      disColor = buttonColor.discuss;
    } else {
      text = WorkflowStates[currentRecord.previousWorkflowState].stateName;
      caption = "To " + text;
      disColor = buttonColor.forward;
    }

    var toPaperDiscussion = (<ActionButton
      text={text}
      captionText={caption}
      disabled={disabledDiscuss}
      handleClick={onMoveToState}
      color={disColor}
    />)
  }

  return (
    <React.Fragment>
      {showExclusionSelect && currentRecord && (
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <SelectExclusionStates
            currentRecord={currentRecord}
            handleExclusionSelection={handleExclusionSelection}
            disabled={disabled}
          />
          {showToExclude && (
            <Box mt={2} ml={3}>
              {excludeButton}
            </Box>
          )}
        </Box>
      )}
      <Box mb={2} display="flex" flexDirection="column" alignItems="center">
        {/* <Box> */}
        <TextField
          id="workflowState"
          label="State"
          variant="outlined"
          size="small"
          disabled
          fullWidth
          margin="dense"
          inputProps={{ style: { fontSize: 13 } }}
          value={
            currentRecord != null ? currentRecord.workflowState : "No record"
          }
        ></TextField>
      </Box>
      <Box alignItems="center" alignContent="center" justifyContent="center">
        <Box mb={2}>{showForward && forwardButton}</Box>
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box mr={2}>{showBackward && backwardButton}</Box>
          <Box>{showPaperDiscussion && toPaperDiscussion}</Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

const needsConfirmation = (currentPaper, action) => {
  let result = { confirm: false, msg: "default" };

  if (currentPaper.workflowState === WorkflowStates.INCOMING.state) {
    if (currentPaper.filenameUploaded !== null && action === Action.FORWARD) {
      result = {
        confirm: true,
        msg: "This record has already an uploaded paper. Do you want to move to Not Found Papers?",
      };
    }
  }

  if (currentPaper.workflowState === WorkflowStates.ANALYSIS.state) {
    if (
      currentPaper.filenameUploadedAnnotated !== null &&
      action === Action.BACKWARD
    ) {
      result = {
        confirm: true,
        msg: "This record has already an annotated uploaded paper. If you move back, the annotated pdf will be lost in the data base (It will not be removed from disk). Do you want to move back to Manual Assessment?",
      };
    }
  }

  return result;
};

// function useTraceUpdate(props) {
//   const prev = React.useRef(props);
//   React.useEffect(() => {
//     const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
//       if (prev.current[k] !== v) {
//         ps[k] = [prev.current[k], v];
//       }
//       return ps;
//     }, {});
//     if (Object.keys(changedProps).length > 0) {
//       console.log("Changed props:", changedProps);
//     }
//     prev.current = props;
//   });
// }
