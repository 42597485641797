import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";

export const ButtonType = {
  DANGER: "danger",
  YELLOW: "yellow",
  ORANGE: "orange",
};

export const DangerButton = createCustomButton("#922b21", "#F13811", "#700a00");
export const OrangeButton = createCustomButton("#922b21", "#ff8b32", "#ef7b22");
export const YellowButton = createCustomButton("#922b21", "#ffac26", "#f48c06");

export function createCustomButton(
  color,
  backgroundColor,
  hover = backgroundColor,
  br = 4,
  mr = 0,
  mt = 0,
  mb = 0,
  ml = 0
) {
  const CustomButton = withStyles((theme) => ({
    root: {
      color: `${theme.palette.getContrastText(color)} !important`,
      backgroundColor: `${backgroundColor} !important`,
      "&:hover": {
        backgroundColor: hover,
      },
      "&:disabled": {
        color: "#bdbdbd",
        backgroundColor: "#e0e0e0",
      },
      marginRight: theme.spacing(mr),
      marginTop: theme.spacing(mt),
      marginBottom: theme.spacing(mb),
      marginLeft: theme.spacing(ml),
      borderRadius: br,
    },
  }))(Button);

  return CustomButton;
}

export const getCustomButton = (type) => {
  let button;
  switch (type) {
    case ButtonType.DANGER:
      button = DangerButton;
      break;
    case ButtonType.YELLOW:
      button = YellowButton;
      break;
    case ButtonType.ORANGE:
      button = OrangeButton;
      break;

    default:
      break;
  }

  return button;
};
