import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { FiberManualRecord, HelpOutline } from "@mui/icons-material";
import  CheckCircleOutlineIcon  from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import api from "../../../api";
import swal from "sweetalert";

interface ValidatePaperAnnotationsButtonProps {
  currentRecord: any;
  useWhiteOutclinedButton: boolean;
}

enum ValidationState {
  INIT,
  SUCCESSFUL,
  ERROR
}

export function ValidatePaperAnnotationsButton({
  currentRecord,
  useWhiteOutclinedButton,
}: ValidatePaperAnnotationsButtonProps) {
  const [open, setOpen] = React.useState(false);
  const [processingValidation, setProcessingValidation] = React.useState(false);
  const [validationIcon, setValidatationIcon] = React.useState<ValidationState>(ValidationState.INIT);
  const [validationTexts, setValidationTexts] = React.useState<[] | null>(null);
  const validateIntervalTime = 60000;

  const onValidateVariables = (e: any) => {
    setProcessingValidation(true);
    api
      .validatePaperAnnotations(currentRecord.id)
      .then((response: any) => {
        setProcessingValidation(false);
        if (response.data.length === 0) {
          setValidationTexts(response.data);
          setValidatationIcon(ValidationState.SUCCESSFUL);
          showValidationSuccesful();
        } else {
          setValidationTexts(response.data);
          setValidatationIcon(ValidationState.ERROR);
          setOpen(true);
        }
      })
      .catch((e) => {
        console.log("Validate Paper error: " + e);
        setProcessingValidation(false);
      });
  };

  const autoValidateVariables = (e: any) => {
    const startRecordId = currentRecord.id;
    api.validatePaperAnnotations(currentRecord.id)
      .then((response: any) => {   
        if (response.data.length === 0) {
          // Only update when the response belongs to the id we started with.
          if (startRecordId === currentRecord.id) {
            setValidatationIcon(ValidationState.SUCCESSFUL);
            setValidationTexts(response.data);
          }
        } else {
          // Only update when the response belongs to the id we started with.
          if (startRecordId === currentRecord.id) {
            setValidatationIcon(ValidationState.ERROR);
            setValidationTexts(response.data);
          }
        }
      })
      .catch((e) => {
        console.log("Validate Paper error: " + e);
      });
  }

  React.useEffect(() => {
    setValidatationIcon(ValidationState.INIT);
    setValidationTexts(null);
    const validationInterval = setInterval(autoValidateVariables, validateIntervalTime);

    return () => {
      clearInterval(validationInterval);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecord]); 

  const showValidationSuccesful = () => {
    let msg = "Validation result: no warnings found";
    swal("Validation result", msg, "success", {
      closeOnClickOutside: false,
    }).then((ok) => {
      setValidatationIcon(ValidationState.SUCCESSFUL);
      // Close any open dialog opened from a validation failure before.
      setOpen(false);
    });
  }

  const showValidationResult = (e: any) => {
    if (validationTexts === null) {
      onValidateVariables(e);
    } else if (validationTexts.length === 0) {
      showValidationSuccesful();
    } else {
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {useWhiteOutclinedButton ? (
        <Button
          size="medium"
          variant="outlined"
          style={{ borderColor: "white", color: validationIcon === ValidationState.ERROR ? "red" : "white", marginRight: 20 }}
          onClick={showValidationResult}
        >
          {processingValidation ? (
            <CircularProgress
              size="1.2rem"
              style={{
                borderColor: "white",
                color: "white",
                marginRight: "0.4rem",
              }}
            />
          ) : null}
          {getValidationIcon()}
          Validate Variables
        </Button>
      ) : (
        <Button
          component="span"
          variant="contained"
          disableRipple
          color={validationIcon === ValidationState.ERROR ? "error" : "secondary"}
          size="medium"
          onClick={showValidationResult}
        >
          {processingValidation ? (
            <CircularProgress
              size="1.2rem"
              style={{
                marginRight: "0.4rem",
              }}
            />
          ) : null}
          <Typography variant="body2" style={{
                display: "inline-flex",
                alignItems: "center"
              }}>
              {getValidationIcon()}
              Validate Variables
         </Typography>
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography>Validation Results</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The validation gave the following results
          </DialogContentText>
          {validationTexts !== null ? (
            <List>
              {validationTexts.map((validationText: string) => {
                return (
                  <ListItem>
                    <FiberManualRecord
                      style={{ fontSize: 10, marginRight: "5px" }}
                    />
                    <Typography>{validationText}</Typography>
                  </ListItem>
                );
              })}
            </List>
          ) : null}
        </DialogContent>
        <DialogActions>
        <Button
          component="span"
          variant="contained"
          disableRipple
          color="secondary"
          size="medium"
          onClick={onValidateVariables}
        >
          {processingValidation ? (
            <CircularProgress
              size="1.2rem"
              style={{
                marginRight: "0.4rem",
              }}
            />
          ) : null}
          <Typography variant="body2" display="block" align="center">
               Re-validate
         </Typography>
        </Button>
          <Button
            variant="contained"
            onClick={() => handleClose()}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function getValidationIcon(): any {
    if (validationIcon === ValidationState.INIT) {
      return (<HelpOutline style={{ marginRight: "3px" }}/>)
    } else if (validationIcon === ValidationState.SUCCESSFUL) {
      return (<CheckCircleOutlineIcon style={{ marginRight: "3px" }}/>)
    }
    return (<ErrorOutlineIcon style={{ marginRight: "3px" }} />);
  }
}
