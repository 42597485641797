import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

import {
  createOutcomeAnnotationText,
  isError,
  isOutcomeGroupResult,
  isTimeDependentResult,
} from "../../../utils/annotation/utils";
import { AnnotationOutcomeContentType } from "../../../model/annotation/AnnotationOutcomeContentType";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { TimeType } from "../../../model/annotation/outcome/TimeType";
import {
  GroupNames,
  GroupNamesWithEmpty,
} from "../../../model/annotation/GroupNames";
import { RangeType } from "../../../model/annotation/outcome/RangeType";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { OutcomeAnnotation } from "../../../model/annotation/outcome/OutcomeAnnotation";

interface Props {
  onConfirm: (
    comment: { text: string; count: string },
    chapter: string,
    annotationType: string,
    outcomeAnnotation: OutcomeAnnotation | null
  ) => void;
  lastOutcomeAnnotation: OutcomeAnnotation | null;
}

interface State {
  outcomeAnnotation: OutcomeAnnotation;
}

function getArrayWithNumbers(count: number): string[] {
  const numberArray: string[] = [""];
  for (let i = 1; i <= count; i++) {
    numberArray.push(i.toString());
  }

  return numberArray;
}

export const AvailableSections: string[] = getArrayWithNumbers(20);

export const countValues: string[] = getArrayWithNumbers(6);

export class OutcomeAnnotationTip extends Component<Props, State> {
  state: State = {
    outcomeAnnotation: {
      resultType:
        this.props.lastOutcomeAnnotation !== null
          ? this.props.lastOutcomeAnnotation.resultType
          : AnnotationOutcomeContentType.PRIMARY.type,
      section1:
        this.props.lastOutcomeAnnotation !== null
          ? this.props.lastOutcomeAnnotation.section1
          : "",
      section2:
        this.props.lastOutcomeAnnotation !== null
          ? this.props.lastOutcomeAnnotation.section2
          : "",
      section3:
        this.props.lastOutcomeAnnotation !== null
          ? this.props.lastOutcomeAnnotation.section3
          : "",
      isAbsoluteTimestamp: false,
      timeType: TimeType.DIFFERENCE,
      timeCount: "",
      count: "",
      groupId1: "A",
      groupId2: "",
      groupIdCustomAddon: "",
      variable: "",
      isCustom: false,
      resultNumber: "",
      rangeType: RangeType.RANGE,
    },
  };

  handleOnChangeRadio = (event: any, isCustom: boolean) => {
    const { outcomeAnnotation } = this.state;
    this.setState({
      outcomeAnnotation: {
        ...outcomeAnnotation,
        variable: event.target.value,
        isCustom: isCustom,
      },
    });
  };

  render() {
    const { outcomeAnnotation } = this.state;

    const generatedOutcome = createOutcomeAnnotationText(outcomeAnnotation);

    return (
      <FormControl
        style={{ width: "500px", padding: "1rem", minHeight: 0, minWidth: 0 }}
        className="Tip__card"
      >
        <Grid container spacing={1}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={2}>
              Outcome
            </Grid>
            <Grid item xs={3} alignItems="flex-start">
              <select
                value={outcomeAnnotation.resultType}
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      resultType: e.target.value,
                    },
                  })
                }
                style={{ width: "105px" }}
              >
                <option
                  key={1}
                  value={AnnotationOutcomeContentType.PRIMARY.type}
                >
                  Primary
                </option>
                <option
                  key={2}
                  value={AnnotationOutcomeContentType.SECONDARY.type}
                >
                  Secondary
                </option>
                <option
                  key={3}
                  value={AnnotationOutcomeContentType.TERTIARY.type}
                >
                  Tertiary
                </option>
                <option
                  key={4}
                  value={AnnotationOutcomeContentType.CONTROL.type}
                >
                  Control Variable
                </option>
                <option
                  key={5}
                  value={AnnotationOutcomeContentType.ADVERSE.type}
                >
                  Adverse Event
                </option>
              </select>
            </Grid>
            <Grid container xs={7} spacing={1} alignItems="center">
              <Grid item xs={3} alignItems="flex-end">
                Section
              </Grid>
              <Grid item xs={3}>
                <select
                  value={outcomeAnnotation.section1}
                  onChange={(e) =>
                    this.setState({
                      outcomeAnnotation: {
                        ...outcomeAnnotation,
                        section1: e.target.value,
                      },
                    })
                  }
                >
                  {AvailableSections.map((value) => {
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3}>
                <select
                  value={outcomeAnnotation.section2}
                  onChange={(e) =>
                    this.setState({
                      outcomeAnnotation: {
                        ...outcomeAnnotation,
                        section2: e.target.value,
                      },
                    })
                  }
                >
                  {AvailableSections.map((value) => {
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              <Grid item xs={3}>
                <select
                  value={outcomeAnnotation.section3}
                  onChange={(e) =>
                    this.setState({
                      outcomeAnnotation: {
                        ...outcomeAnnotation,
                        section3: e.target.value,
                      },
                    })
                  }
                >
                  {AvailableSections.map((value) => {
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </Grid>
            </Grid>
          </Grid>

          {/* Outcome name */}
          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ paddingTop: "0.75rem" }}
          >
            <Grid item xs={9}>
              <Radio
                checked={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.OUTCOME_NAME.type
                }
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.OUTCOME_NAME.type}
                size="small"
              ></Radio>
              Name (e.g. outcome.prim1)
            </Grid>
            <Grid item xs={3}>
              <select
                value={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.OUTCOME_NAME.type
                    ? outcomeAnnotation.count.toString()
                    : ""
                }
                disabled={
                  outcomeAnnotation.variable !==
                  AnnotationOutcomeContentType.OUTCOME_NAME.type
                }
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      count: e.target.value,
                    },
                  })
                }
              >
                {countValues.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
                ID
              </select>
            </Grid>

            {/* Time stamp */}
            <Grid item xs={3}>
              <Radio
                checked={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.TIME.type
                }
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.TIME.type}
                size="small"
              />
              Time
            </Grid>
            <Grid item xs={9}>
              <FormControlLabel
                disabled={
                  outcomeAnnotation.variable !==
                  AnnotationOutcomeContentType.TIME.type
                }
                control={
                  <Checkbox
                    size="small"
                    onChange={(e) =>
                      this.setState({
                        outcomeAnnotation: {
                          ...outcomeAnnotation,
                          isAbsoluteTimestamp: e.target.checked,
                        },
                      })
                    }
                    value={outcomeAnnotation.isAbsoluteTimestamp}
                  />
                }
                label="Absolute timestamp (e.g. year 2022)"
              />
            </Grid>
          </Grid>
          {/* Outcome spec */}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <Radio
                checked={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.SPECIFICATION.type
                }
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.SPECIFICATION.type}
                size="small"
              ></Radio>
              Specification
            </Grid>
            <Grid item xs={3}>
              <select
                value={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.SPECIFICATION.type
                    ? outcomeAnnotation.count.toString()
                    : ""
                }
                disabled={
                  outcomeAnnotation.variable !==
                  AnnotationOutcomeContentType.SPECIFICATION.type
                }
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      count: e.target.value,
                    },
                  })
                }
              >
                {countValues.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
                ID
              </select>
            </Grid>
          </Grid>
          {/* Outcome measure */}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <Radio
                checked={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.MEASURE.type
                }
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.MEASURE.type}
                size="small"
              ></Radio>
              Measure (e.g. risk ratio, change, %)
            </Grid>
            <Grid item xs={3}>
              <select
                value={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.MEASURE.type
                    ? outcomeAnnotation.count.toString()
                    : ""
                }
                disabled={
                  outcomeAnnotation.variable !==
                  AnnotationOutcomeContentType.MEASURE.type
                }
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      count: e.target.value,
                    },
                  })
                }
              >
                {countValues.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
                ID
              </select>
            </Grid>
          </Grid>
          {/* Outcome measure type*/}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <Radio
                checked={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.MEASURE_TYPE.type
                }
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.MEASURE_TYPE.type}
                size="small"
              ></Radio>
              Measure type (e.g. mean or median)
            </Grid>
            <Grid item xs={3}>
              <select
                value={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.MEASURE_TYPE.type
                    ? outcomeAnnotation.count.toString()
                    : ""
                }
                disabled={
                  outcomeAnnotation.variable !==
                  AnnotationOutcomeContentType.MEASURE_TYPE.type
                }
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      count: e.target.value,
                    },
                  })
                }
              >
                {countValues.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
                ID
              </select>
            </Grid>
          </Grid>

          {/*Results*/}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={9}>
              <Radio
                checked={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.RESULT.type
                }
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.RESULT.type}
                size="small"
              ></Radio>
              Result
            </Grid>
            <Grid item xs={3}>
              <select
                value={
                  outcomeAnnotation.variable ===
                  AnnotationOutcomeContentType.RESULT.type
                    ? outcomeAnnotation.count.toString()
                    : ""
                }
                disabled={
                  outcomeAnnotation.variable !==
                  AnnotationOutcomeContentType.RESULT.type
                }
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      count: e.target.value,
                    },
                  })
                }
              >
                {countValues.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>

          {/*Errors*/}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <Radio
                checked={isError(outcomeAnnotation.variable)}
                onChange={(e) => this.handleOnChangeRadio(e, false)}
                value={AnnotationOutcomeContentType.SD.type}
                size="small"
              ></Radio>
              Error
            </Grid>
            <Grid item xs={9}>
              <RadioGroup
                row
                aria-labelledby="error-type-radio-buttons-group-label"
                name="error-type-radio-buttons-group"
              >
                <FormControlLabel
                  // value="Time difference"
                  control={
                    <Radio
                      disabled={!isError(outcomeAnnotation.variable)}
                      onChange={(e) => this.handleOnChangeRadio(e, false)}
                      checked={
                        outcomeAnnotation.variable ===
                        AnnotationOutcomeContentType.SD.type
                      }
                      value={AnnotationOutcomeContentType.SD.type}
                      size="small"
                    />
                  }
                  label="SD"
                />
                <FormControlLabel
                  value="95CI"
                  control={
                    <Radio
                      onChange={(e) => this.handleOnChangeRadio(e, false)}
                      disabled={!isError(outcomeAnnotation.variable)}
                      checked={
                        outcomeAnnotation.variable ===
                        AnnotationOutcomeContentType.CI95.type
                      }
                      value={AnnotationOutcomeContentType.CI95.type}
                      size="small"
                    />
                  }
                  label="95CI"
                />
                <FormControlLabel
                  value="IQR"
                  control={
                    <Radio
                      onChange={(e) => this.handleOnChangeRadio(e, false)}
                      disabled={!isError(outcomeAnnotation.variable)}
                      checked={
                        outcomeAnnotation.variable ===
                        AnnotationOutcomeContentType.IQR.type
                      }
                      value={AnnotationOutcomeContentType.IQR.type}
                      size="small"
                    />
                  }
                  label="IQR"
                />
                <FormControlLabel
                  value="PVALUE"
                  control={
                    <Radio
                      onChange={(e) => this.handleOnChangeRadio(e, false)}
                      disabled={!isError(outcomeAnnotation.variable)}
                      checked={
                        outcomeAnnotation.variable ===
                        AnnotationOutcomeContentType.PVALUE.type
                      }
                      value={AnnotationOutcomeContentType.PVALUE.type}
                      size="small"
                    />
                  }
                  label="P-Value"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          {/*Ranges*/}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}></Grid>
            <Grid item xs={9}>
              <RadioGroup
                row
                aria-labelledby="range-type-radio-buttons-group-label"
                name="range-type-radio-buttons-group"
              >
                <FormControlLabel
                  value="Range"
                  control={
                    <Radio
                      disabled={!isError(outcomeAnnotation.variable)}
                      onChange={(e) =>
                        this.setState({
                          outcomeAnnotation: {
                            ...outcomeAnnotation,
                            rangeType: RangeType.RANGE,
                          },
                        })
                      }
                      checked={outcomeAnnotation.rangeType === RangeType.RANGE}
                      value={RangeType.RANGE.toString()}
                      size="small"
                    />
                  }
                  label="Range"
                />
                <FormControlLabel
                  value="Low"
                  control={
                    <Radio
                      onChange={(e) =>
                        this.setState({
                          outcomeAnnotation: {
                            ...outcomeAnnotation,
                            rangeType: RangeType.LOW,
                          },
                        })
                      }
                      disabled={!isError(outcomeAnnotation.variable)}
                      checked={outcomeAnnotation.rangeType === RangeType.LOW}
                      value={RangeType.LOW}
                      size="small"
                    />
                  }
                  label="Low"
                />
                <FormControlLabel
                  value="Up"
                  control={
                    <Radio
                      onChange={(e) =>
                        this.setState({
                          outcomeAnnotation: {
                            ...outcomeAnnotation,
                            rangeType: RangeType.UP,
                          },
                        })
                      }
                      disabled={!isError(outcomeAnnotation.variable)}
                      checked={outcomeAnnotation.rangeType === RangeType.UP}
                      value={RangeType.UP}
                      size="small"
                    />
                  }
                  label="Up"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          {/* {T0, Tend and so on} */}
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3}>
              Result time
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                aria-labelledby="time-type-radio-buttons-group-label"
                name="time-type-radio-buttons-group"
              >
                <FormControlLabel
                  value="Difference"
                  control={
                    <Radio
                      disabled={!isTimeDependentResult(outcomeAnnotation)}
                      onChange={(e) =>
                        this.setState({
                          outcomeAnnotation: {
                            ...outcomeAnnotation,
                            timeType: TimeType.DIFFERENCE,
                          },
                        })
                      }
                      checked={
                        outcomeAnnotation.timeType === TimeType.DIFFERENCE
                      }
                      size="small"
                    />
                  }
                  label="Difference"
                />
                <FormControlLabel
                  value="T0"
                  control={
                    <Radio
                      onChange={(e) =>
                        this.setState({
                          outcomeAnnotation: {
                            ...outcomeAnnotation,
                            timeType: TimeType.START,
                          },
                        })
                      }
                      disabled={!isTimeDependentResult(outcomeAnnotation)}
                      checked={outcomeAnnotation.timeType === TimeType.START}
                      size="small"
                    />
                  }
                  label="T0"
                />
                <FormControlLabel
                  value="Tend"
                  control={
                    <Radio
                      onChange={(e) =>
                        this.setState({
                          outcomeAnnotation: {
                            ...outcomeAnnotation,
                            timeType: TimeType.END,
                          },
                        })
                      }
                      disabled={!isTimeDependentResult(outcomeAnnotation)}
                      checked={outcomeAnnotation.timeType === TimeType.END}
                      size="small"
                    />
                  }
                  label="Tend"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={2}>
              <select
                value={outcomeAnnotation.timeCount}
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      timeCount: e.target.value,
                    },
                  })
                }
              >
                {AvailableSections.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={5}>
              Result number (if multiple)
            </Grid>
            <Grid item xs={7}>
              <select
                value={outcomeAnnotation.resultNumber}
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      resultNumber: e.target.value,
                    },
                  })
                }
              >
                {getArrayWithNumbers(4).map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            alignItems="center"
            style={{ paddingTop: "1rem" }}
          >
            <Grid item xs={3}>
              Group(s)
            </Grid>
            <Grid item xs={2}>
              <select
                value={outcomeAnnotation.groupId1}
                disabled={!isOutcomeGroupResult(outcomeAnnotation.variable)}
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      groupId1: e.target.value,
                    },
                  })
                }
              >
                {GroupNames.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={2}>
              <select
                value={outcomeAnnotation.groupId2}
                disabled={!isOutcomeGroupResult(outcomeAnnotation.variable)}
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      groupId2: e.target.value,
                    },
                  })
                }
              >
                {GroupNamesWithEmpty.map((value) => {
                  return (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={5}>
              <input
                type="text"
                value={outcomeAnnotation.groupIdCustomAddon}
                onChange={(e) =>
                  this.setState({
                    outcomeAnnotation: {
                      ...outcomeAnnotation,
                      groupIdCustomAddon: e.target.value,
                    },
                  })
                }
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography
          component="div"
          style={{ paddingTop: "1.5rem", paddingBottom: "0.5rem" }}
        >
          Outcome{": "}
          <Box fontWeight="fontWeightMedium" display="inline">
            {generatedOutcome[0]}
          </Box>{" "}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={generatedOutcome[0] === ""}
          onClick={(event) => {
            event.preventDefault();
            this.props.onConfirm(
              {
                text: generatedOutcome[0],
                count: outcomeAnnotation.count,
              },
              generatedOutcome[1],
              AnnotationType.CONTENT.type,
              outcomeAnnotation
            );
          }}
        >
          Save
        </Button>
      </FormControl>
    );
  }
}
